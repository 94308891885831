// -- Setup --------------------------------------------------------------------
    import React from 'react';
    import ReactDOM from 'react-dom';
    import { BrowserRouter, Switch, Route, Redirect } from 'react-router-dom';
    import styles from './style/style';
    import { getMenu, getPage, getSettings, control_panel,
             showMessage, verify, login, logout, getPageByUrl } from './js/Functions';
    const appRoot = document.getElementById('app');
// -----------------------------------------------------------------------------

// -- Components ---------------------------------------------------------------
    import Header from './components/Header';
    import Content from './components/Content';
    import Contact from './components/Contact';
    import Footer from './components/Footer';
    import Cms from './components/Cms';
    import Cms_controls from './components/Cms_controls';
    import Cms_pages from './components/Cms_pages';
    import Cms_addPage from './components/Cms_addPage';
    import Cms_siteSettings from './components/Cms_siteSettings';
// -----------------------------------------------------------------------------

/* -- Notes --------------------------------------------------------------------
    For development, change 'basePath' to the root of the site
    e.g. basePath: '/cms/'
   -------------------------------------------------------------------------- */

class App extends React.Component{
    constructor(props){
        super(props);
        this.state ={
            menu: [],
            basePath: '/',
            pageContent: '',
            credentials: {},
            settings: {}
        }
        this.changePage = this.changePage.bind(this);
        this.login = this.login.bind(this);
        this.logout = this.logout.bind(this);
        this.updateCms = this.updateCms.bind(this);
    }
    updateCms(){
        var self = this;
        setTimeout(() => {
            getMenu(self);
            getSettings(self);
        }, 1000);
    }
    login(data){
        login(this,data);
    }
    logout(){
        const credentials = {};
        this.setState({
            credentials
        })
        logout(this);
        showMessage("You have logged out");
    }
    changePage(pageId){
        getPage(this,pageId);
        if( this.state.credentials.cmsLogin ) control_panel('close');
        verify(this);
    }
    componentDidMount(){
        // -- If a url refresh, get page id by url --
        if( window.location.pathname !== '/'){
            getPageByUrl(this, window.location.pathname)
        }else{
            getPage(this,1);
        }
        getMenu(this);
        getSettings(this);
    }
    render(){
        document.body.scrollTop = 0;
        document.documentElement.scrollTop = 0;
        let routes = [];
        this.state.menu.map((item) => {
            if( item.name == 'home' ){
                // -- Home page route --
                    routes.push(
                        <Route exact path={this.state.basePath}>
                            <Content pageContent={this.state.pageContent} />
                        </Route>
                    )
            }else if( item.name !== 'contact' ){
                // -- Other pages route (but not 'contact') --
                    routes.push(
                        <Route path={this.state.basePath + item.name}>
                            <Content pageContent={this.state.pageContent} />
                        </Route>
                    )
                    item.children.map((item) => {
                        routes.push(
                            <Route path={this.state.basePath + item.name}>
                                <Content pageContent={this.state.pageContent} />
                            </Route>
                        )
                    })
            }
        })

        // -- Add route for contact page ---------------------------------------
            routes.push(
                <Route path={this.state.basePath + 'contact'}>
                    <Contact pageContent={this.state.pageContent}
                             settings={this.state.settings} />
                </Route>
            )
        // ---------------------------------------------------------------------

        // -- Add route for CMS login ------------------------------------------
            const cmsPath = this.state.basePath + 'cms';
            routes.push(
                <Route path={cmsPath}>
                    <Cms cmsLogin={this.state.credentials.cmsLogin} login={this.login} />
                </Route>
            )
        // ---------------------------------------------------------------------

        // -- Add cms routes if user is logged in ------------------------------
            if( this.state.credentials.cmsLogin ){
                routes.push(
                    <Route path={this.state.basePath + 'pages'}>
                        <Cms_pages updateCms={this.updateCms} />
                    </Route>
                )
                routes.push(
                    <Route path={this.state.basePath + 'addPage'}>
                        <Cms_addPage updateCms={this.updateCms} />
                    </Route>
                )
                routes.push(
                    <Route path={this.state.basePath + 'settings'}>
                        <Cms_siteSettings settings={this.state.settings} updateCms={this.updateCms} />
                    </Route>
                )
            }
        // ---------------------------------------------------------------------

        // -- Add cms controls if user is logged in ----------------------------
            let controls = this.state.credentials.cmsLogin ?
                <Cms_controls logout={this.logout} username={this.state.credentials.userName} /> : null;
        // ---------------------------------------------------------------------

        return(
            <div className="main_content">
                <Header
                    menu={this.state.menu}
                    basePath={this.state.basePath}
                    changePage={this.changePage}
                    settings={this.state.settings} />
                <Switch>
                    {routes}
                </Switch>
                <Footer
                    menu={this.state.menu}
                    basePath={this.state.basePath}
                    changePage={this.changePage}
                    settings={this.state.settings} />
                {controls}
                <div id="messages"></div>
            </div>
        )
    }
}

// -- Render -------------------------------------------------------------------
    ReactDOM.render(<BrowserRouter><App /></BrowserRouter>, appRoot )
// -----------------------------------------------------------------------------
