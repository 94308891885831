// -- Setup --------------------------------------------------------------------
    import React from 'react';
    import { Link } from 'react-router-dom';
    import { control_panel } from '../js/Functions';
// -----------------------------------------------------------------------------

// -- Components ---------------------------------------------------------------
// -----------------------------------------------------------------------------

class Cms_controls extends React.Component{
    constructor(props){
        super(props);
        this.controls = this.controls.bind(this);
        this.logout = this.logout.bind(this);
    }
    controls(){
        control_panel('openClose');
    }
    logout(){
        this.props.logout();
    }
    render(){
        return(
            <div className="control" id="cms_controls">
                <h2>CMS control</h2>
                <p>Logged in as <strong>{this.props.username}</strong></p>
                <Link to="settings" className="icon chevron">Site settings</Link>
                <Link to="pages" className="icon chevron">Edit a page</Link>
                <Link to="addPage" className="icon chevron">Add a page</Link>
                <button onClick={this.logout}>Log out</button>
                <p className="openClose" onClick={this.controls}><span className='icon cog' id="control_icon"></span></p>
            </div>
        )
    }
}

export default Cms_controls;
