// -- Setup --------------------------------------------------------------------
    import React from 'react';
    import { login } from '../js/Functions';
// -----------------------------------------------------------------------------

// -- Components ---------------------------------------------------------------
// -----------------------------------------------------------------------------

class Cms_login extends React.Component{
    constructor(props){
        super(props);
        this.state = {
            cms_username: '',
            cms_password: ''
        }
        this.formSubmit = this.formSubmit.bind(this);
        this.formChange = this.formChange.bind(this);
    }
    formChange(e){
        this.setState({
            [e.target.name]: e.target.value
        })
    }
    formSubmit(e){
        e.preventDefault();
        const data = {
            username: this.state.cms_username,
            password: this.state.cms_password
        }
        document.getElementById('loginError').style.display = 'none';
        if( this.state.cms_username && this.state.cms_password ){
            this.props.login(data);
        }else{
            document.getElementById('loginError').style.display = 'block';
        }
    }
    componentDidMount(){
        document.getElementById('cms_username').focus();
    }
    render(){
        return(
            <div className="content">
                <h2>Content Management System - log in</h2>
                <div className="form">
                    <p>
                        <label  htmlFor="username">Username</label>
                        <input  type="text"
                                name="cms_username"
                                id="cms_username"
                                value={this.state.username}
                                onChange={this.formChange} />
                    </p>
                    <p>
                        <label  htmlFor="password">Password</label>
                        <input  type="password"
                                name="cms_password"
                                id="cms_password"
                                value={this.state.password}
                                onChange={this.formChange} />
                    </p>
                    <p className="formControl">
                        <input type="submit" value="Log in" onClick={this.formSubmit} />
                    </p>
                    <p className="errorMessage" id="loginError">Please complete all form fields</p>
                </div>
            </div>
        )
    }
}

export default Cms_login;
