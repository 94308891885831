// -- Setup --------------------------------------------------------------------
    import React from 'react';
    import { control_panel, getAllPages } from '../js/Functions';
// -----------------------------------------------------------------------------

// -- Components ---------------------------------------------------------------
    import Cms_editPage from './Cms_editPage';
// -----------------------------------------------------------------------------

class Cms_pages extends React.Component{
    constructor(props){
        super(props);
        this.state = {
            pages: [],
            selected_page: 3
        }
        this.selectPage = this.selectPage.bind(this);
        this.updateCms = this.updateCms.bind(this);
    }
    updateCms(){
        getAllPages(this);
        this.props.updateCms();
    }
    selectPage(e){
        const selected_page = this.state.pages[parseInt(e.target.parentElement.dataset.target)];
        this.setState({
            selected_page: selected_page
        })
        document.getElementById('page-selector').style.display = 'none';
        document.getElementById('page-edit').style.display = 'block';
    }
    componentDidMount(){
        control_panel('close');
        getAllPages(this);
    }
    render(){
        let pageTable = [];
        let parentOptions = [];
        var classList;
        this.state.pages.map((page, index) => {
            classList = page.active == '0' ? 'inactive' : '';
            // -- Create page list - don't include 'contact' -------------------
                // if(page.title !== 'Contact'){
                    pageTable.push(
                        <tr className={classList} onClick={this.selectPage} data-target={index}>
                            <td>{page.title}</td>
                            <td class="notSmall">/{page.name}</td>
                            <td>{page.menuOrder}</td>
                            <td class="notMed">{page.permanent}</td>
                            <td class="notMed">{page.inHeader}</td>
                            <td class="notMed">{page.inFooter}</td>
                        </tr>
                    )
                // }
            // -----------------------------------------------------------------
            if( !parseInt(page.permanent) ){
                parentOptions.push(
                    {
                        id: page.id,
                        title: page.title
                    }
                )
            }
        })
        return(
            <>
                <div className="content">
                    <h1>Edit a page</h1>
                    <div id="page-selector">
                        <p>Choose a page to edit</p>
                        <table className="pages-table">
                            <thead>
                                <tr>
                                    <th>Title</th>
                                    <th className="notSmall">URL</th>
                                    <th>Order</th>
                                    <th className="notMed">Perm</th>
                                    <th className="notMed">In header</th>
                                    <th className="notMed">In footer</th>
                                </tr>
                            </thead>
                            <tbody>
                                {pageTable}
                            </tbody>
                        </table>
                    </div>
                    <Cms_editPage selected_page={this.state.selected_page} updateCms={this.updateCms} pages={this.state.pages} />
                </div>
            </>
        )
    }
}

export default Cms_pages;
