// -- Setup --------------------------------------------------------------------
    import React from 'react';
    import { control_panel, saveSettings, validateForm } from '../js/Functions';
// -----------------------------------------------------------------------------

// -- Components ---------------------------------------------------------------
// -----------------------------------------------------------------------------

class Cms_siteSettings extends React.Component{
    constructor(props){
        super(props);
        this.state = {
            action: 'saveSiteSettings',
            site_title: '',
            company_name: '',
            email_address: '',
            telephone_number: '',
            show_email: 1,
            show_telephone: 1,
            localChange: false
        }
        this.formChange = this.formChange.bind(this);
        this.formSubmit = this.formSubmit.bind(this);
    }
    formChange(e){
        const value = e.target.type === 'checkbox' ? e.target.checked : e.target.value;
        const name = e.target.name;
        this.setState({
            [name]: value,
            localChange: true
        })
    }
    formSubmit(e){
        e.preventDefault();
        // -- Create an array of form elements that require validation (have ['data-errormessage']) --
            const elements = document.querySelectorAll('[data-errormessage]');
        // -------------------------------------------------------------------------------------------
        const formValid = validateForm(elements);
        if(formValid){
            saveSettings(this.state);
            this.props.updateCms();
        }
    }
    componentDidMount(){
        control_panel('close');
        const settings = this.props.settings;
        this.setState({
            site_title: settings.site_title,
            company_name: settings.company_name,
            email_address: settings.email_address,
            show_email: parseInt(settings.display_email),
            telephone_number: settings.telephone_number,
            show_telephone: parseInt(settings.display_telephone)
        })
    }

    render(){
        const show_email = this.state.show_email ? 'selected' : '';
        const show_telephone = this.state.show_telephone ? 'selected' : '';
        return(
            <div className="content">
                <h1>Site settings</h1>
                <div className="form">
                    <p>
                        <label htmlFor="site-title">Site title</label>
                        <input
                            type="text"
                            name="site_title"
                            id="site_title"
                            value={this.state.site_title}
                            onChange={this.formChange}
                            data-errormessage="Please enter a site title" />
                    </p>
                    <p>
                        <label htmlFor="company_name">Company name</label>
                        <input
                            type="text"
                            name="company_name"
                            id="company_name"
                            value={this.state.company_name}
                            onChange={this.formChange}
                            data-errormessage="Please enter a menu title" />
                    </p>
                    <p className="inline_element">
                        <label htmlFor="email_address">Email address</label>
                        <input type="text" name="email_address" id="email_address" value={this.state.email_address} onChange={this.formChange} />
                    </p>
                    <p className={'checkbox ' + show_email}>
                        <label htmlFor="show_email"><span><span></span></span>Show on site</label>
                        <input type="checkbox" name="show_email" id="show_email" checked={this.state.show_email}
                        onChange={this.formChange} />
                    </p>
                    <p className="inline_element">
                        <label htmlFor="telephone_number">Telephone</label>
                        <input type="text" name="telephone_number" id="telephone_number" value={this.state.telephone_number} onChange={this.formChange} />
                    </p>
                    <p className={'checkbox ' + show_telephone}>
                        <label htmlFor="show_telephone"><span><span></span></span>Show on site</label>
                        <input type="checkbox" name="show_telephone" id="show_telephone" checked={this.state.show_telephone}
                        onChange={this.formChange} />
                    </p>
                    <p>
                        <input type="submit" value="Save settings" onClick={this.formSubmit} />
                    </p>
                </div>
            </div>
        )
    }
}

export default Cms_siteSettings;
