// -- Setup --------------------------------------------------------------------
    import React from 'react';
    import { Link } from 'react-router-dom';
// -----------------------------------------------------------------------------

// -- Components ---------------------------------------------------------------
    import Menu from './Menu';
// -----------------------------------------------------------------------------

class Footer extends React.Component{
    constructor(props){
        super(props);
        this.changePage = this.changePage.bind(this);
        this.terms = this.terms.bind(this);
    }
    changePage(pageId){
        this.props.changePage(pageId);
    }
    terms(e){
        this.props.changePage(e.currentTarget.dataset.pageid);
    }
    render(){
        const year = new Date().getFullYear();
        return(
            <div className="footer">
                <p>All content &copy;{year} {this.props.settings.company_name}</p>
                <Link to={this.props.basePath + "terms"} onClick={this.terms} data-pageid='2' className="footerLink">Terms and conditions</Link>
                <div className="menu">
                    <Menu
                        menu={this.props.menu}
                        location="footer"
                        basePath={this.props.basePath}
                        changePage={this.changePage} />
                </div>
                {/* TEMP !!  <Link to={this.props.basePath + "cms"} className="footerLink">CMS</Link>  TEMP !! */}
            </div>
        )
    }
}

export default Footer;
