// -- Setup --------------------------------------------------------------------
    import React from 'react';
// -----------------------------------------------------------------------------

// -- Components ---------------------------------------------------------------
    import MenuItem from './MenuItem';
    import MenuItemChildren from './MenuItemChildren';
// -----------------------------------------------------------------------------


class Menu extends React.Component{
    constructor(props){
        super(props);
        this.changePage = this.changePage.bind(this);
    }
    changePage(pageId){
        this.props.changePage(pageId);
    }
    render(){
        let menu = [], menuItem;
        this.props.menu.map((item) => {
            const pushItem = this.props.location == 'header' ? parseInt(item.inHeader) : parseInt(item.inFooter);
            const hasChildren = item.children.length ? true : false;
            if(hasChildren && this.props.location != 'footer'){
                menuItem = <MenuItemChildren
                            id={item.id}
                            title={item.title}
                            name={item.name}
                            basePath={this.props.basePath}
                            changePage={this.changePage}
                            children={item.children} />
            }else{
                menuItem = <MenuItem
                            id={item.id}
                            title={item.title}
                            name={item.name}
                            basePath={this.props.basePath}
                            changePage={this.changePage} />
            }
            if( pushItem ){
                menu.push( menuItem )
            }
        })
        menu.push(
            <MenuItem
                id='3'
                title='Contact'
                name='contact'
                basePath={this.props.basePath}
                changePage={this.changePage} />
        )
        return(
            <ul>
                {menu}
            </ul>
        )
    }
}

export default Menu;
