// -- Setup --------------------------------------------------------------------
    import React from 'react';
    import { getPage, sendEmail } from '../js/Functions';
// -----------------------------------------------------------------------------

class Contact extends React.Component{
    constructor(props){
        super(props);
        this.state = {
            contact_name: '',
            contact_email: '',
            contact_tel: '',
            message: '',
            siteTitle: this.props.settings.site_title,
            emailTo: this.props.settings.email_address
        }
        this.formChange = this.formChange.bind(this);
        this.formSubmit = this.formSubmit.bind(this);
    }
    formChange(e){
        const value = e.target.value;
        const name = e.target.name;
        this.setState({
            [name]: value
        })
    }
    formSubmit(e){
        e.preventDefault();
        sendEmail(this.state);
    }
    render(){
        const settings = this.props.settings;
        let contact = [];
        if( parseInt(settings.display_telephone) ){
            contact.push(
                <p>
                    <span>Telephone:&nbsp;</span>
                    <a href={"tel:" + settings.telephone_number} className="telephone">
                        {settings.telephone_number}
                    </a>
                </p>
            )
        }
        if( parseInt(settings.display_email) ){
            contact.push(
                <p>
                    <span>Email:&nbsp;</span>
                    <a href={"mailto:" + settings.email_address} className="email">{settings.email_address}</a>
                </p>
            );
        }
        return(
            <div className="content">
                <h2>Contact us</h2>
                <div className="contact_content">
                    <div id="thank_you">
                        <h3>Thanks for contacting us</h3>
                        <p>We aim to reply to all contacts within three working days</p>
                        <p>If your enquiry is more urgent, please call us</p>
                    </div>
                    <div class="form" id="contact_form">
                        <p>
                            <label htmlFor="contact_name">Name</label>
                            <input type="text" name="contact_name" value={this.state.contact_name}
                                   id="contact_name" onChange={this.formChange} />
                        </p>
                        <p>
                            <label htmlFor="contact_email">Email</label>
                            <input type="text" name="contact_email" value={this.state.contact_email}
                                   id="contact_email" onChange={this.formChange} />
                        </p>
                        <p>
                            <label htmlFor="contact_tel">Telephone</label>
                            <input type="text" name="contact_tel" value={this.state.contact_tel}
                                   id="contact_tel" onChange={this.formChange} />
                        </p>
                        <p>
                            <label htmlFor="message">Message</label>
                            <textarea name="message" id="message" onChange={this.formChange}>
                                {this.state.message}
                            </textarea>
                        </p>
                        <p className="formControls">
                            <input type="submit" value="Send message" onClick={this.formSubmit} />
                        </p>
                    </div>
                    <div className="dbContent">
                        {contact}
                        <div className="fromDb" dangerouslySetInnerHTML={ {__html: this.props.pageContent} } />
                    </div>
                </div>
                <p id="contact_error">Please enter a name, message and email <em>or</em> telephone</p>
            </div>
        )
    }
}

export default Contact;
