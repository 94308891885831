// -- Setup --------------------------------------------------------------------
    import React from 'react';
    import { Link } from 'react-router-dom';
// -----------------------------------------------------------------------------

// -- Components ---------------------------------------------------------------
// -----------------------------------------------------------------------------


class MenuItem extends React.Component{
    constructor(props){
        super(props);
        this.changePage = this.changePage.bind(this);
    }
    changePage(e){
        this.props.changePage(e.currentTarget.dataset.pageid);
    }
    render(){
        const linkTo = (this.props.name == 'home') ? this.props.basePath : this.props.name;
        return(
            <li>
                <Link to={linkTo} onClick={this.changePage} data-pageid={this.props.id}>
                    {this.props.title}
                </Link>
            </li>
        )
    }
}

export default MenuItem;
