// -- Setup --------------------------------------------------------------------
    import React from 'react';
// -----------------------------------------------------------------------------

// -- Components ---------------------------------------------------------------
    import Cms_login from './Cms_login';
    import Cms_access from './Cms_access';
// -----------------------------------------------------------------------------

class Cms extends React.Component{
    constructor(props){
        super(props);
        this.login = this.login.bind(this);
    }
    login(data){
        this.props.login(data);
    }
    render(){
        const cmsLogin = this.props.cmsLogin;
        let cmsContent = <Cms_login login={this.login} />;
        if(cmsLogin){
            cmsContent = <Cms_access />;
        }
        return(
            <>
                {cmsContent}
            </>
        )
    }
}

export default Cms;
