
const axios = require('axios').default;

// -- Get menu from database ---------------------------------------------------
    const getMenu = (self) => {
            const data = {
                'action': 'getMenu'
            }
            axios.post('data.php',data)
                .then(
                    data => {

                        let removeItems = [];
                        data.data.map((item,index) => {
                            data.data[index].children = [];
                        })

                        data.data.map((item,index) => {
                            if( item.parent ){
                                data.data[index].hasParent = true;
                                data.data.find(obj => {
                                    if( obj.id === item.parent){
                                        obj.children.push(item);
                                    };
                                })
                                removeItems.push(index);
                            }
                        })
                        // -- Loop through array in reverse so indices are preserved --
                            removeItems.slice().reverse().forEach( function(value){
                                data.data.splice(value,1);
                            })
                        // ------------------------------------------------------------
                        self.setState({
                            menu: data.data
                        });

                    }
                )
    }
// -----------------------------------------------------------------------------

// -- Get a page from the database by ID ---------------------------------------
    const getPage = ( self, pageId ) => {
        const data = {
            'action': 'getPage',
            'pageId': pageId
        }
        axios.post('data.php',data)
            .then(
                data => {
                    self.setState({
                        pageContent: data.data
                    })
                }
            )
    }
// -----------------------------------------------------------------------------

// -- Get a page from the database based on url --------------------------------
    const getPageByUrl = ( self, pageUrl ) => {
        let url = pageUrl.replace('/','');
        const data = {
            'action': 'getPageByUrl',
            'pageUrl': url
        }
        axios.post('data.php',data)
            .then(
                data => {
                    getPage(self,data.data);
                }
            )
    }
// -----------------------------------------------------------------------------

// -- Delete a page from the database ------------------------------------------
    const deletePage = (pageId) => {
        const data = {
            'action': 'deletePage',
            'pageId': pageId
        }
        axios.post('data.php',data)
            .then(
                data => {
                    showMessage('The page has been deleted');
                    return true;
                }
            )
    }
// -----------------------------------------------------------------------------

// -- Get all pages from the database ------------------------------------------
    const getAllPages = (self) => {
        const data = {
            'action': 'getAllPages'
        }
        axios.post('data.php',data)
            .then(
                data => {
                    self.setState({
                        pages: data.data
                    })
                }
            )
    }
// -----------------------------------------------------------------------------

// -- Save a page --------------------------------------------------------------
    const savePage = (pageData) => {
        axios.post('data.php',pageData)
            .then(
                data => {
                    showMessage('The page has been saved');
                    return true;
                }
            )
    }
// -----------------------------------------------------------------------------

// -- Add a page ---------------------------------------------------------------
    const addPage = (pageData) => {
        axios.post('data.php', pageData)
            .then(
                data => {
                    showMessage('The new page has been added');
                    return true;
                }
            )
    }
// -----------------------------------------------------------------------------

// -- Get settings -------------------------------------------------------------
    const getSettings = (self) => {
        const data = {
            'action': 'getSiteSettings'
        }
        axios.post('data.php',data)
            .then(
                data => {
                    const settings = data.data[0];
                    self.setState({
                        settings: {
                            site_title: settings.site_title,
                            company_name: settings.company_name,
                            email_address: settings.email_address,
                            display_email: settings.display_email,
                            telephone_number: settings.telephone_number,
                            display_telephone: settings.display_telephone
                        }
                    })
                }
            )
    }
// -----------------------------------------------------------------------------

// -- Save settings ------------------------------------------------------------
    const saveSettings = (pageData) => {
        axios.post('data.php', pageData)
            .then(
                data => {
                    showMessage('The site settings have been saved');
                    return true;
                }
            )
    }
// -----------------------------------------------------------------------------

// -- Login to the CMS ---------------------------------------------------------
    const login = ( self, data ) => {
        const formData = {
            'action': 'login',
            'username': data.username,
            'password': data.password
        }
        axios.post('data.php', formData)
            .then(
                data => {
                    if( data.data ){
                        const credentials = {
                            cmsLogin: true,
                            userName: data.data
                        }
                        self.setState({
                            credentials
                        })
                        showMessage("You have logged in");
                    }else{
                        showMessage("Incorrect login");
                    }
                }
            )
    }
    const logout = (self) => {
        const formData = {
            'action': 'logout'
        }
        axios.post('data.php', formData)
            .then(
                data => {
                    self.setState({
                        credentials: {}
                    })
                }
            )
    }
    const verify = (self) => {
        const formData = {
            'action': 'verify'
        }
        axios.post('data.php', formData)
            .then(
                data => {
                     let loggedIn = data.data ? true : false;
                     self.setState({
                         credentials: {
                             cmsLogin: loggedIn,
                             userName: self.state.credentials.userName
                         }
                     })
                    }
            )
    }
// -----------------------------------------------------------------------------

// -- Hide/close the CMS control panel -----------------------------------------
    const control_panel = (action) => {
        const cp = document.getElementById('cms_controls');
        const ci = document.getElementById('control_icon');
        if( action == 'openClose' ){
            cp.classList.contains('open') ? cp.classList.remove('open') : cp.classList.add('open');
            ci.classList.remove('cog');
            ci.classList.remove('back');
            cp.classList.contains('open') ? ci.classList.add('back') : ci.classList.add('cog');
        }else{
            cp.classList.remove('open');
            ci.classList.remove('back');
            ci.classList.add('cog');
        }
    }
// -----------------------------------------------------------------------------

// -- Show a message -----------------------------------------------------------
    const showMessage = (message) => {
        const messages = document.getElementById('messages');
        messages.innerHTML = message;
        messages.classList.add('show');
        let messageTimer = setTimeout( () => {
            messages.classList.remove('show');
            clearTimeout(messageTimer);
        },5000)
    }
// -----------------------------------------------------------------------------

// -- Validate form ------------------------------------------------------------
    const validateForm = (elements) => {
        let formValid = true;
        // -- Remove all existing error messages --
            let validation_errors = document.getElementsByClassName('validation_error');
            while(validation_errors[0]){
                validation_errors[0].parentNode.removeChild(validation_errors[0])
            }
        // ----------------------------------------
        for(const value of elements){
            // -- Create variables ---------------------
                let thisError = false;
                const element = document.getElementById(value.id);
                const formValue = element.value;
                const valType = value.getAttribute('data-valid');
            // -----------------------------------------
            // -- Create error message -----------------
                let errorMessage = document.createElement('span');
                errorMessage.innerHTML =  element.getAttribute('data-errormessage');
                errorMessage.classList.add('validation_error');
            // -----------------------------------------
            // -- Determine if the input has an error --
                thisError = formValue != '' ? false : true;
                if( valType == 'number' && isNaN(formValue) ){
                    thisError = true;
                }
                if( valType == 'charnospace' && !formValue.match(/^[a-z\d\-_]+$/i) ){
                    thisError = true;
                }
            // -----------------------------------------
            // -- Append error message -----------------
                if(thisError){
                    element.parentNode.appendChild(errorMessage);
                }
            // -----------------------------------------
            formValid = formValid ? !thisError : false;

        }
        return formValid;
    }
// -----------------------------------------------------------------------------

// -- Show/Hide tool tip -------------------------------------------------------
    const showToolTip = (e) => {
        let infoMessage = document.createElement('span');
        infoMessage.classList.add('info_message');
        infoMessage.innerHTML = e.dataset.info;
        e.parentNode.appendChild(infoMessage);
    }
    const hideToolTip = () => {
        let validation_errors = document.getElementsByClassName('info_message');
        while(validation_errors[0]){
            validation_errors[0].parentNode.removeChild(validation_errors[0])
        }
    }
// -----------------------------------------------------------------------------

// -- Send email ---------------------------------------------------------------
    const sendEmail = (data) => {
        let formValid = false;
        document.getElementById('contact_error').style.display = 'none';
        if( data.contact_name !== '' && data.message !== '' &&
            ( data.contact_tel !== '' || data.contact_email !== '' )
        ){
            formValid = true;
        }else{
            document.getElementById('contact_error').style.display = 'block';
        }
        if(formValid){
            const formData = [{
                contact_name: data.contact_name,
                contact_email: data.contact_email,
                contact_tel: data.contact_tel,
                message: data.message,
                siteTitle: data.siteTitle,
                emailTo: data.emailTo
            }];
            axios.post('contactEmail.php', JSON.stringify(formData))
                .then(
                    data => {
                        document.getElementById('contact_form').style.display = 'none';
                        document.getElementById('thank_you').style.display = 'block';
                    }
                )
        }
    }
// -----------------------------------------------------------------------------

export {
    getMenu, getPage, getPageByUrl, deletePage, getAllPages, savePage, addPage,
    getSettings, saveSettings, login, logout, control_panel, showMessage,
    verify, validateForm, showToolTip, hideToolTip, sendEmail };
