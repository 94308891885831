// -- Setup --------------------------------------------------------------------
    import React from 'react';
    import { Link } from 'react-router-dom';
// -----------------------------------------------------------------------------

// -- Components ---------------------------------------------------------------
    import Menu from './Menu';
// -----------------------------------------------------------------------------


class Header extends React.Component{
    constructor(props){
        super(props);
        this.changePage = this.changePage.bind(this);
        this.changePageFromTitle = this.changePageFromTitle.bind(this);
        this.mobileMenu = this.mobileMenu.bind(this);
    }
    windowResize(){
        if(window.innerWidth > 768 ){
            document.getElementById('menu').style.display = 'block';
        }else{
            document.getElementById('menu').style.display = 'none';
        }
    }
    componentDidMount(){
        this.windowResize();
        window.addEventListener('resize', this.windowResize);
    }
    mobileMenu(){
        document.getElementById('menu').style.display = 'block';
    }
    changePage(pageId){
        console.log("changePage");
        console.log(window.innerWidth);
        if(window.innerWidth <= 768 ){
            document.getElementById('menu').style.display = 'none';
        }
        this.props.changePage(pageId);
    }
    changePageFromTitle(e){
        this.props.changePage(e.currentTarget.dataset.pageid);
    }
    render(){
        const settings = this.props.settings;
        let contact = [];
        if( parseInt(settings.display_telephone) ){
            contact.push(
                <a href={"tel:" + settings.telephone_number} className="telephone">
                    {settings.telephone_number}
                </a>
            )
        }
        if( parseInt(settings.display_email) ){
            contact.push(
                <a href={"mailto:" + settings.email_address} className="email">{settings.email_address}</a>
            );
        }
        return(
            <div className="header">
                <h1><Link to={this.props.basePath} onClick={this.changePageFromTitle} data-pageid="1">{settings.site_title}</Link></h1>
                <div className="contact">
                    {contact}
                </div>
                <div className="menu" id="menu">
                    <Menu
                        menu={this.props.menu}
                        location="header"
                        basePath={this.props.basePath}
                        changePage={this.changePage} />
                </div>
                <a id="mobile_menu" onClick={this.mobileMenu}></a>
            </div>
        )
    }
}

export default Header;
