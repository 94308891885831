// -- Setup --------------------------------------------------------------------
    import React from 'react';
    import { getPage } from '../js/Functions';
// -----------------------------------------------------------------------------

// -- Components ---------------------------------------------------------------
// -----------------------------------------------------------------------------

class Content extends React.Component{
    constructor(props){
        super(props);
    }
    render(){
        return(
            <div className="content" dangerouslySetInnerHTML={ {__html: this.props.pageContent} } />
        )
    }
}

export default Content;
