// -- Setup --------------------------------------------------------------------
    import React from 'react';
// -----------------------------------------------------------------------------

class Cms_access extends React.Component{
    constructor(props){
        super(props);
    }
    render(){
        return(
            <>
                <div className="content">
                    <h1>CMS</h1>
                    <p>You are now logged into the Content Management System.</p>
                    <p>Please use the controls on the left to make changes.</p>
                </div>
            </>
        )
    }
}

export default Cms_access;
