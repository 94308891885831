// -- Setup --------------------------------------------------------------------
    import React from 'react';
    import { savePage, deletePage, showMessage, validateForm, showToolTip, hideToolTip } from '../js/Functions';
    import { Editor } from '@tinymce/tinymce-react';
// -----------------------------------------------------------------------------

class Cms_editPage extends React.Component{
    constructor(props){
        super(props);
        this.state = {
            action: 'savePage',
            page_id: 0,
            page_title: '',
            page_url: '',
            page_content: '',
            page_order: '',
            page_footer: 0,
            page_header: 0,
            page_active: 0,
            page_permanent: 0,
            page_parent: null,
            parentOptions: [],
            localChange: false
        }
        this.formChange = this.formChange.bind(this);
        this.formSubmit = this.formSubmit.bind(this);
        this.formCancel = this.formCancel.bind(this);
        this.pageDelete = this.pageDelete.bind(this);
    }
    hideInfo(){
        hideToolTip();
    }
    showInfo(e){
        showToolTip(e.target);
    }
    formSubmit(e){
        e.preventDefault();
        const page_content =  window.parent.tinymce.get('page_content').getContent();
        const formData = {...this.state, page_content: page_content};
        // -- Create an array of form elements that require validation (have ['data-errormessage']) --
            const elements = document.querySelectorAll('[data-errormessage]');
        // -------------------------------------------------------------------------------------------
        // -- Submit the form if it is valid ---------------------------------------------------------
            const formValid = validateForm(elements);
            if(formValid){
                savePage(formData);
                this.props.updateCms();
                document.getElementById('page-selector').style.display = 'block';
                document.getElementById('page-edit').style.display = 'none';
            }
        // -------------------------------------------------------------------------------------------
    }
    formCancel(e){
        e.preventDefault();
        document.getElementById('page-selector').style.display = 'block';
        document.getElementById('page-edit').style.display = 'none';
    }
    formChange(e){
        const value = e.target.type === 'checkbox' ? e.target.checked : e.target.value;
        const name = e.target.name;
        this.setState({
            [name]: value,
            localChange: true
        })
    }
    pageDelete(e){
        let conf = confirm("Are you sure you want to delete this page?\nDeleting is permanent and cannot be undone.");
        if(conf){
            deletePage(e.target.dataset.pageid);
            this.props.updateCms();
            document.getElementById('page-selector').style.display = 'block';
            document.getElementById('page-edit').style.display = 'none';
        }
    }
    static getDerivedStateFromProps(props,state){
        // -- Detect local state change ----------------------------------------
            if (state.localChange) { return { localChange: false} }
        // ---------------------------------------------------------------------

        // -- Provide 'proper' booleans for checkboxes -------------------------
            const page_footer_checked = parseInt(props.selected_page.inFooter) ? true : false;
            const page_header_checked = parseInt(props.selected_page.inHeader) ? true : false;
            const page_active_checked = parseInt(props.selected_page.active) ? true : false;
            const page_permanent_checked = parseInt(props.selected_page.permanent) ? true : false;
        // ---------------------------------------------------------------------

        // -- Create menu parent options ---------------------------------------
            let pagesWithParents = [];
            let pagesWithChildren = [];
            let show_parent = true;
            // -- Create arrays of pages with parent/children ------------------
                props.pages.map((item) => {
                    if( item.parent ){
                        pagesWithParents.push( item.id );
                        pagesWithChildren.push( parseInt(item.parent) );
                    }
                })
            // -----------------------------------------------------------------
            // -- Create array of options --------------------------------------
                let parentOptions = [];
                parentOptions.push( <option value="null">No parent</option>);
                props.pages.map((item) => {
                    if( !parseInt(item.permanent) &&
                        item.parent == null &&
                        pagesWithParents.indexOf(item.id) < 0 &&
                        item.id !== props.selected_page.id){
                            parentOptions.push(
                                <option value={item.id}>{item.title}</option>
                            )
                        }
                })
                show_parent = pagesWithChildren.indexOf(parseInt(props.selected_page.id)) != -1 ? false : true;
            // -----------------------------------------------------------------
        // ---------------------------------------------------------------------

        // -- Return updated state ---------------------------------------------
            return{
                page_id: props.selected_page.id,
                page_title: props.selected_page.title,
                page_url: props.selected_page.name,
                page_content: props.selected_page.content,
                page_order: props.selected_page.menuOrder,
                page_footer: page_footer_checked,
                page_header: page_header_checked,
                page_active: page_active_checked,
                page_permanent: page_permanent_checked,
                page_parent: props.selected_page.parent,
                show_parent: true,
                parentOptions: parentOptions,
                show_parent: show_parent
            }
        // ---------------------------------------------------------------------
    }
    render(){
        const selectState = this.state.page_parent || '';
        const header_selected = this.state.page_header ? 'selected' : '';
        const footer_selected = this.state.page_footer ? 'selected' : '';
        const page_active = this.state.page_active ? 'selected' : '';
        return(
            <div id="page-edit">
                <h2>Edit page - {this.props.selected_page.title}</h2>
                <div className="form">
                    {!this.state.page_permanent &&
                        <>
                            <p>
                                <label htmlFor="page_title">Menu title</label>
                                <input
                                    type="text"
                                    name="page_title"
                                    id="page_title"
                                    value={this.state.page_title}
                                    onChange={this.formChange}
                                    data-errormessage="Please enter a menu title" />
                                <span
                                    className="info"
                                    data-info="This is the text that is displayed in the menu"
                                    onMouseOver={this.showInfo}
                                    onMouseOut={this.hideInfo}
                                    >i</span>
                            </p>
                            <p>
                                <label htmlFor="page_url">URL</label>
                                <input
                                    type="text"
                                    name="page_url"
                                    id="page_url"
                                    value={this.state.page_url}
                                    onChange={this.formChange}
                                    data-errormessage="Please enter a URL" />
                                <span
                                    className="info"
                                    data-info="This is the text that is displayed in the URL"
                                    onMouseOver={this.showInfo}
                                    onMouseOut={this.hideInfo}
                                    >i</span>
                            </p>
                            {this.state.show_parent &&
                                <p>
                                    <label htmlFor="page_parent">Parent</label>
                                    <select
                                        name="page_parent"
                                        id="page_parent"
                                        value={selectState}
                                        onChange={this.formChange}>
                                        {this.state.parentOptions}
                                    </select>
                                    <span
                                        className="info"
                                        data-info="This is the parent menu item, under which the page is displayed"
                                        onMouseOver={this.showInfo}
                                        onMouseOut={this.hideInfo}
                                        >i</span>
                                </p>
                            }
                            <p>
                                <label htmlFor="page_order">Order</label>
                                <input
                                    type="text"
                                    name="page_order"
                                    id="page_order"
                                    value={this.state.page_order}
                                    onChange={this.formChange}
                                    data-errormessage="Please enter a display order (a number)"
                                    data-valid="number" />
                                <span
                                    className="info"
                                    data-info="This is the order in which the page is displayed in the menu"
                                    onMouseOver={this.showInfo}
                                    onMouseOut={this.hideInfo}
                                    >i</span>
                            </p>
                        </>
                    }
                    {this.state.page_permanent &&
                        <p>This is a <strong>Permanent page</strong> which cannot be deleted</p>
                    }
                    {!this.state.page_permanent &&
                        <>
                            <p className={'checkbox ' + header_selected}>
                                <label htmlFor="page_header"><span><span></span></span>In header menu</label>
                                <input
                                    type="checkbox"
                                    name="page_header"
                                    id="page_header"
                                    checked={this.state.page_header}
                                    onChange={this.formChange} />
                            </p>
                            <p className={'checkbox ' + footer_selected}>
                                <label htmlFor="page_footer"><span><span></span></span>In footer menu</label>
                                <input
                                    type="checkbox"
                                    name="page_footer"
                                    id="page_footer"
                                    checked={this.state.page_footer}
                                    onChange={this.formChange} />
                            </p>
                            <p className={'checkbox ' + page_active}>
                                <label htmlFor="page_active"><span><span></span></span>Page active</label>
                                <input
                                    type="checkbox"
                                    name="page_active"
                                    id="page_active"
                                    checked={this.state.page_active}
                                    onChange={this.formChange} />
                            </p>
                        </>
                    }
                    <p>
                        <Editor
                            name="page_content"
                            id="page_content"
                            initialValue={this.state.page_content}
                            value={this.state.page_content}
                            apiKey="fa6l1scyn3vng90ciosv1bzbtbdelkvqc2buddfsd9anomqb"
                            onChange={this.formChange}
                            onEditorChange={this.editorChange}
                            init={{
                              height: 400,
                              menubar: false,
                              image_uploadtab: true,
                              images_upload_url: 'upload.php',
                              plugins: [
                                'advlist autolink lists link image',
                                'charmap print preview anchor help',
                                'searchreplace visualblocks code',
                                'insertdatetime media table paste wordcount'
                              ],
                              toolbar:
                                'undo redo | formatselect | bold italic | \
                                alignleft aligncenter alignright | \
                                bullist numlist outdent indent | image',
                            }}
                        />
                    </p>
                    <p className="formControls">
                        <input type="submit" onClick={this.formSubmit} value="Save changes" />
                        <input type="button" onClick={this.formCancel} value="Cancel changes" />
                        {!this.state.page_permanent &&
                            <input type="button" onClick={this.pageDelete} value="Delete page"
                                   data-pageid={this.state.page_id} className="deletePage" />
                        }
                    </p>
                </div>
            </div>
        )
    }
}

export default Cms_editPage;
