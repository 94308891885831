// -- Setup --------------------------------------------------------------------
    import React from 'react';
    import { control_panel, getAllPages, addPage, validateForm, showToolTip, hideToolTip } from '../js/Functions';
    import { Editor } from '@tinymce/tinymce-react';
// -----------------------------------------------------------------------------

class Cms_addPage extends React.Component{
    constructor(props){
        super(props);
        this.state = {
            action: 'addPage',
            page_title: '',
            page_url: '',
            page_content: '',
            page_order: '',
            page_footer: 1,
            page_header: 1,
            page_active: 1,
            page_parent: null,
            localChange: false,
            pageAdded: false
        }
        this.formSubmit = this.formSubmit.bind(this);
        this.formChange = this.formChange.bind(this);
    }
    hideInfo(){
        hideToolTip();
    }
    showInfo(e){
        showToolTip(e.target);
    }
    formSubmit(e){
        e.preventDefault();
        const page_content =  window.parent.tinymce.get('page_content').getContent();
        // -- Create an array of form elements that require validation (have ['data-errormessage']) --
            const elements = document.querySelectorAll('[data-errormessage]');
        // -------------------------------------------------------------------------------------------
        // -- Submit the form if it is valid ---------------------------------------------------------
            const formValid = validateForm(elements);
            if(formValid){
                const formData = {...this.state, page_content: page_content};
                addPage(formData);
                this.props.updateCms();
                this.setState({
                    pageAdded: true
                })
            }
        // -------------------------------------------------------------------------------------------
    }
    formChange(e){
        const value = e.target.type === 'checkbox' ? e.target.checked : e.target.value;
        const name = e.target.name;
        this.setState({
            [name]: value
        })
    }
    componentDidMount(){
        control_panel('close');
        getAllPages(this);
        document.getElementById('page_title').focus();
    }
    componentDidUpdate(){
        // -- Detect local state change ----------------------------------------
            if (this.state.localChange) { return { localChange: false} }
        // ---------------------------------------------------------------------

        // -- Create arry of pages with parents --------------------------------
            const allPages = this.state.pages;
            let pagesWithParents = [];
            if( allPages ){
                allPages.map((item) => {
                    if( item.parent ){
                        pagesWithParents.push( item.id );
                    }
                })
            }
        // ---------------------------------------------------------------------

        // -- Create array of options ------------------------------------------
            let parentOptions = [];
            parentOptions.push( <option value="null">No parent</option>);
            allPages.map((item) => {
                if( !parseInt(item.permanent) &&
                    item.parent == null &&
                    pagesWithParents.indexOf(item.id) < 0){
                        parentOptions.push(
                            <option value={item.id}>{item.title}</option>
                        )
                    }
            })
            this.setState({
                parentOptions: parentOptions,
                localChange: true

            })
        // ---------------------------------------------------------------------
    }
    render(){
        const header_selected = this.state.page_header ? 'selected' : '';
        const footer_selected = this.state.page_footer ? 'selected' : '';
        const page_active = this.state.page_active ? 'selected' : '';
        return(
            <div className="content">
                <h1>CMS - add page</h1>
                    {this.state.pageAdded
                        ?
                            <>
                                <h2>Your new page has been added</h2>
                                <p>Your can navigate to it using the menu above</p>
                                <p>If you wish to edit the new page, choose it from the Edit/Add page list</p>
                            </>
                        :
                            <div id="page-add">
                                <div className="form">
                                    <p>
                                        <label htmlFor="page_title">Menu title</label>
                                        <input
                                            type="text"
                                            name="page_title"
                                            id="page_title"
                                            value={this.state.page_title}
                                            onChange={this.formChange}
                                            data-errormessage="Please enter a menu title" />
                                        <span
                                            className="info"
                                            data-info="This is the text that is displayed in the menu"
                                            onMouseOver={this.showInfo}
                                            onMouseOut={this.hideInfo}
                                            >i</span>
                                    </p>
                                    <p>
                                        <label htmlFor="page_url">URL</label>
                                        <input
                                            type="text"
                                            name="page_url"
                                            id="page_url"
                                            value={this.state.page_url}
                                            onChange={this.formChange}
                                            data-errormessage="Please enter a URL"
                                            data-valid="charnospace" />
                                        <span
                                            className="info"
                                            data-info="This is the text that is displayed in the URL, can only contain letters, numbers, dashes and underscores"
                                            onMouseOver={this.showInfo}
                                            onMouseOut={this.hideInfo}
                                            >i</span>
                                    </p>
                                    <p>
                                        <label htmlFor="page_parent">Parent</label>
                                        <select
                                            name="page_parent"
                                            id="page_parent"
                                            value=""
                                            onChange={this.formChange}>
                                            {this.state.parentOptions}
                                        </select>
                                        <span
                                            className="info"
                                            data-info="This is the parent menu item, under which the page is displayed"
                                            onMouseOver={this.showInfo}
                                            onMouseOut={this.hideInfo}
                                            >i</span>
                                    </p>
                                    <p>
                                        <label htmlFor="page_order">Display order</label>
                                        <input
                                            type="text"
                                            name="page_order"
                                            id="page_order"
                                            value={this.state.page_order}
                                            onChange={this.formChange}
                                            data-errormessage="Please enter a display order (a number)"
                                            data-valid="number" />
                                        <span
                                            className="info"
                                            data-info="This is the order in which the page is displayed in the menu"
                                            onMouseOver={this.showInfo}
                                            onMouseOut={this.hideInfo}
                                            >i</span>
                                    </p>
                                    <p className={'checkbox ' + header_selected}>
                                        <label htmlFor="page_header"><span><span></span></span>In header menu</label>
                                        <input
                                            type="checkbox"
                                            name="page_header"
                                            id="page_header"
                                            checked={this.state.page_header}
                                            onChange={this.formChange} />
                                    </p>
                                    <p className={'checkbox ' + footer_selected}>
                                        <label htmlFor="page_footer"><span><span></span></span>In footer menu</label>
                                        <input
                                            type="checkbox"
                                            name="page_footer"
                                            id="page_footer"
                                            checked={this.state.page_footer}
                                            onChange={this.formChange} />
                                    </p>
                                    <p className={'checkbox ' + page_active}>
                                        <label htmlFor="page_active"><span><span></span></span>Page active</label>
                                        <input
                                            type="checkbox"
                                            name="page_active"
                                            id="page_active"
                                            checked={this.state.page_active}
                                            onChange={this.formChange} />
                                    </p>
                                    <p>
                                        <Editor
                                            name="page_content"
                                            id="page_content"
                                            initialValue={this.state.page_content}
                                            value={this.state.page_content}
                                            apiKey="fa6l1scyn3vng90ciosv1bzbtbdelkvqc2buddfsd9anomqb"
                                            onChange={this.formChange}
                                            onEditorChange={this.editorChange}
                                            init={{
                                              height: 400,
                                              menubar: false,
                                              image_uploadtab: true,
                                              images_upload_url: 'upload.php',
                                              plugins: [
                                                'advlist autolink lists link image',
                                                'charmap print preview anchor help',
                                                'searchreplace visualblocks code',
                                                'insertdatetime media table paste wordcount'
                                              ],
                                              toolbar:
                                                'undo redo | formatselect | bold italic | \
                                                alignleft aligncenter alignright | \
                                                bullist numlist outdent indent | image',
                                            }}
                                        />
                                    </p>
                                    <p className="formControls">
                                        <input type="submit" onClick={this.formSubmit} value="Save changes" />
                                        <input type="button" onClick={this.formCancel} value="Cancel changes" />
                                    </p>
                                </div>
                            </div>
                    }
                </div>
        )
    }
}

export default Cms_addPage;
